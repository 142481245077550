<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="isAdd ? '新增部门' : '编辑部门'" @closed="form = null" width="500px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="80px" v-if="form">
      <el-form-item label="部门名称" prop="name">
        <el-input v-model="form.name" :maxlength="20" />
      </el-form-item>
      <el-form-item label="状态" prop="enabled">
        <el-radio-group v-model="form.enabled">
          <el-radio-button :label="true">正常</el-radio-button>
          <el-radio-button :label="false">禁用</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="上级部门">
        <tree-picker v-model="form.pid" url="api/dept" :params="{enterpriseId: form.enterpriseId}" :exclude-keys="isAdd ? null : [form.id]" :root-key="0" parent-field="pid" accordion placeholder="选择上级部门，不选择则为顶级部门" clearable flat style="width:100%" />
      </el-form-item>
      <el-form-item label="负责地区">
        <quick-cascader v-model="form.areaList" filterable url="api/area" :params="{entId: form.enterpriseId}" placeholder="不选择时负责所有地区" multiple check-strictly clearable flat expand-trigger="hover" style="width:100%" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from "@/api/dept";

export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      form: null,
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }]
      }
    };
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    doSubmit() {
      this.$refs["form"].validate().then(_ => {
        let ds = JSON.parse(JSON.stringify(this.form));
        ds.areaList = (ds.areaList || []).map(id => {
          return { areaId: id };
        });
        this.loading = true;
        (this.isAdd ? add : edit)(ds)
          .then(res => {
            this.$notify({
              title: "新增成功",
              type: "success",
              duration: 2500
            });
            this.$emit("submit", this.isAdd, res || ds);
            this.dialog = false;
          })
          .finally(_ => {
            this.loading = false;
          });
      });
    },
    doEdit() {
      this.loading = true;
      edit(this.form)
        .then(res => {
          this.resetForm();
          this.$notify({
            title: "修改成功",
            type: "success",
            duration: 2500
          });
          this.$parent.init();
        })
        .finally(_ => {
          this.loading = false;
        });
    },
    resetForm(form, enterpriseId) {
      if (form) {
        form = JSON.parse(JSON.stringify(form));
        form.areaList = (form.areaList || []).map(o => {
          return o.areaId;
        });
        this.form = form;
      } else {
        this.form = {
          id: null,
          name: "",
          pid: null,
          enabled: true,
          enterpriseId: enterpriseId
        };
      }
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
</style>
